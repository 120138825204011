<template>
    <div class="">
        <page-loading-view v-if="isLoading" />
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import PageLoadingView from "@Platon/components/pages/PageLoadingView"
import NavigationMixin from "@Platon/mixins/NavigationMixin"

export default {
    name: "AsyncPage",
    data() {
        return {
            isLoading: true
        }
    },
    components: { PageLoadingView },
    mixins: [NavigationMixin],
    computed: {
        ...mapGetters({
            menu: "platon/menu",
            /** @type User */
            user: "platon/user"
        })
    },
    watch: {
        menu: {
            handler(v) {
                if (v.length > 0) {
                    const homePage = v.find((i) => i.isHomepage)
                    if (homePage) {
                        this.navigateTo(homePage.link, this.user)
                    } else {
                        this.$router.push({ name: "profile-page" })
                    }

                    this.isLoading = false
                }
            },
            deep: true
        }
    }
}
</script>
